import React from 'react';

const DotImg = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
  >
    <circle
      cx="6"
      cy="3"
      r="3"
      fill={color}
    />
  </svg>
);

export default DotImg;
