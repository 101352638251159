import React, { useState, useEffect } from "react";
import "../App.css";
import { logs } from "../libs/apiCalls";

import { gptDestroySlots } from "../libs/gptAdsManager";
// import Extend60 from './Page4Connect';
// import WozaPage from './OnlinePage';

import Popmodal from "../components/Modal";
import VideoErrorPage from "./VideoErrorPage";
// const sessionID = '11111xxxxxBBBB-CCCC111113333'
// const defaultTarget = {test: "allEverything", color: "allmine"}

function RewardedVideo({ sessionParam }) {
  //const [NextPage, setNextPage] = useState(false);
  const [videoErrorState, setVideoErrorState] = useState(false);

  const [RewardGranted, setRewardGranted] = useState(false);
  const [showValid, setshowValid] = useState(false);
  function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/");
    return result[3];
  }

  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    var rewardedSlot;
    var RewardedValid = false;
    googletag.cmd.push(function () {
      var adPath = sessionParam["adPath"] + "video_pri";
      rewardedSlot = googletag.defineOutOfPageSlot(
        // '/21967555500/thinkzone/video_pri',
        adPath,
        googletag.enums.OutOfPageFormat.REWARDED
      );

      if (rewardedSlot) {
        rewardedSlot.addService(googletag.pubads());
        // var Targeting = {lsale: "TW026", partner: "TW"}
        // Object.keys(Targeting).map(key => (
        //   //console.log(key, Targeting[key])
        //   googletag.pubads().setTargeting(key, Targeting[key])
        // ));

        googletag
          .pubads()
          .addEventListener("rewardedSlotReady", function (event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
            var Impressiondetails = {
              sessionID: sessionParam["sessionID"],
              event: "rewardedSlotReady",
              slotID: slotId,
              serviceName: "thinkzone",
              slot: slotId,
            };
            logs(Impressiondetails, sessionParam);
            event.makeRewardedVisible();
          });

        googletag
          .pubads()
          .addEventListener("rewardedSlotClosed", function (event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
            //console.log(slotId)
            setTimeout(() => {
              if (RewardedValid === true) {
                dismissRewardedAd();
              } else {
                if (slotId === "video_pri") {
                  //console.log("session 1 video slot closed")
                  googletag.destroySlots([rewardedSlot]);
                  setVideoErrorState(true);
                }
              }
            }, 1000);
            // if (slotId === 'video_pri'){
            //   console.log("session 1 video slot closed")
            //   googletag.destroySlots([rewardedSlot]);
            //   setVideoErrorState(true)
            // }
          });

        googletag
          .pubads()
          .addEventListener("rewardedSlotGranted", function (event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
            var rewardGrantedEvent = {
              sessionID: sessionParam["sessionID"],
              event: "rewardedSlotGranted",
              slotID: slotId,
              serviceName: "thinkzone",
              slot: slotId,
              payload: 1,
            };
            RewardedValid = true;
            logs(rewardGrantedEvent, sessionParam);
            setTimeout(() => {
              dismissRewardedAd();
            }, 2000);
          });
        googletag.display(rewardedSlot);
        //refreshAds();
      }
    });

    function refreshAds() {
      googletag.cmd.push(() => {
        googletag.pubads().refresh();
      });
    }

    function dismissRewardedAd() {
      googletag.destroySlots([rewardedSlot]);
      setRewardGranted(true);
    }
  }, [sessionParam]);

  if (videoErrorState) return <VideoErrorPage sessionParam={sessionParam} />;

  if (RewardGranted) {
    gptDestroySlots();
    return (
      <Popmodal
        show={showValid}
        close={() => setshowValid(false)}
        sessionParam={sessionParam}
      />
    );
  }
}

export default RewardedVideo;
