import React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";
import Text from "../components/Text";

// import Footer from './Footer';

import ProgressBar from "../components/ProgressBar";

// import Terms from '../components/TermsModal';
import { TextContainer } from "../components/Layout";

import { createSlots } from "../libs/gptAdsManager";

const CustomTextContainer = styled(TextContainer)`
  margin: 5vh 8vh 1vh;
  display: block;
`;

const HeaderText = styled(Text.Text)`
  margin-bottom: 1vh;
  display: block;
`;

const HeaderInfo = styled(Text.Text)`
  font-size: 2vh;
  display: block;
`;
const AppHeader = styled.div`
  padding: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  // margin: 2vh;
`;

const CustomTextParagraph = styled(Text.Paragraph)`
  margin-top: 3.5vh;
  font-size: 1.8vh;
  margin-top: 1vh;
  padding-bottom: 14px;
`;

// const SvgContainer = styled.div`
//   height: 6vh;
//   width: 10vh;
// `;

const Online2 = ({ sessionParam }) => {
  // const [modalState, setModalState] = useState(false);
  const [seconds, setSeconds] = useState(1800);

  const googletag = window.googletag || (window.googletag = { cmd: [] });

  function refreshAds() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  useEffect(() => {
    var adSlots = {
      slots: [
        {
          id: "online_header",
          sizes: [
            [320, 50],
            [300, 100],
            [300, 50],
          ],
        },
        {
          id: "online_display",
          sizes: [
            [336, 280],
            [300, 250],
            [200, 200],
            [250, 250],
          ],
        },
        {
          id: "browseb",
          sizes: [[211, 46]],
        },
      ],
    };
    var adPath = sessionParam["adPath"];
    createSlots(adPath, adSlots);
    //refreshAds();
  }, [sessionParam]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) setSeconds((v) => v - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="online-page">
      <section id="page">
        <AppHeader>
          <header>
            <div
              id="online_header"
              style={{ width: "320px", height: "50px", margin: "0 auto" }}
            />
          </header>
        </AppHeader>
        <main>
          <CustomTextContainer>
            <HeaderText className="bold-text">Congratulations</HeaderText>
            <HeaderInfo className="bold-text">
              You&#39;re now connected to
              <br /> {sessionParam["partner_portal_description"]} Free Wi-Fi
            </HeaderInfo>
            <Text.Text className="num-pad">
              {Math.floor(seconds / 30)}:
              {"".concat(seconds % 30).padStart(2, "0")}
            </Text.Text>
            <HeaderInfo>Minutes left</HeaderInfo>
            <HeaderInfo className="bold-text">Happy browsing!</HeaderInfo>
          </CustomTextContainer>

          <div className="main-block">
            {/* <AdvertisingSlot id="online_display" /> */}
            <div
              id="online_display"
              style={{ width: "300px", height: "250px", margin: "0 auto" }}
            />
          </div>
        </main>

        <nav>
          <ProgressBar page={4} />
          <div
            id="browseb"
            style={{ width: "221px", height: "46px", margin: "0 auto" }}
          />
        </nav>

        <footer>
          {/* <LogoContainer>
                      <CustomTextParagraph>Powered by</CustomTextParagraph>

                  </LogoContainer>
                            <div 
                               id="powerby"
                               style={{ width: '300px', height: '50px' , margin: '0 auto'}}
                            /> */}
          {/* <Footer step={1} setModal={{ setModalState }}/>

                    {modalState ? <Terms setModal={{ setModalState }} /> : null} */}
        </footer>
      </section>
    </div>
  );
};

export default Online2;
