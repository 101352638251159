import "../App.css";
import { useState, useEffect } from "react";
import styled from "styled-components";

import Ad2 from "./Page2";
import Footer from "./Footer";

import Terms from "../components/TermsModal";

import ProgressBar from "../components/ProgressBar";

import Btn from "../components/Button";
import Text from "../components/Text";

import { SectionContainer, BtnContainer } from "../components/ResponsiveLayout";
import { TextContainer } from "../components/Layout";

import { createSlots, gptDestroySlots } from "../libs/gptAdsManager";

const CustomTextContainer = styled(TextContainer)`
  margin: 2vh 8vh 2vh;
`;

const AppHeader = styled.div`
  padding-top: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  // margin: 2vh;
`;

const CustomTextParagraph = styled(Text.Paragraph)`
  margin-top: 3.5vh;
  font-size: 1.8vh;
  margin-top: 2vh;
  //margin-bottom: 2vh;
  justify-content: center;
  height: 4vh;
`;

const Box = styled.div`
  background: #fff;
  margin: auto;
  //width: 30vw;
  max-width: 50vh;
  height: 29vh;
  box-shadow: 0 9px 17px 12px rgba(195, 192, 192, 0.5);
  padding: 3px 20px 0px 20px;
  white-space: pre-wrap;
`;
const googletag = window.googletag || (window.googletag = { cmd: [] });

function Ad1({ sessionParam }) {
  const [NextPage, setNextPage] = useState(false);
  const [modalState, setModalState] = useState(false);
  function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/");
    return result[3];
  }
  useEffect(() => {
    googletag.pubads().addEventListener("slotOnload", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);
      if (slotId === "page1_display") {
        setTimeout(cenas, 2000);
      }
    });
  }, []);

  useEffect(() => {
    var adSlots = {
      slots: [
        {
          id: "page1_header",
          sizes: [
            [320, 50],
            [320, 100],
            [300, 100],
            [300, 50],
          ],
        },
        {
          id: "page1_display",
          //sizes: [[300, 250],[336,280],[200,200],[250,250]]
          sizes: [
            [300, 600],
            [120, 600],
            [160, 600],
            [300, 250],
            [336, 280],
          ],
        },
        {
          id: "powerby",
          sizes: [[300, 50]],
        },
      ],
    };
    // var adPath = '/21967555500/thinkzone/'
    var adPath = sessionParam["adPath"];
    createSlots(adPath, adSlots);
    //refreshAds();
  }, [sessionParam]);

  if (NextPage) {
    // DestroyAd()
    // console.log("Ad Destroyed")
    gptDestroySlots();
    return <Ad2 sessionParam={sessionParam} />;
    //return <Rewarded />
  }

  // This is a Javascript Function that handles a timer and attaches css depending on the setTimeout
  setTimeout(cenas, 5000);

  function refreshAds() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  function cenas() {
    var elementos = document.querySelectorAll(".boxed");
    elementos.forEach(muda);
  }

  function muda(item, index) {
    item.style.opacity = 1;
    item.style.transition = "opacity 1s";
  }

  return (
    <div className="page-1">
      <section id="page">
        {/* <Container> */}
        <AppHeader>
          <header>
            <div
              id="page1_header"
              style={{ width: "320px", height: "100px", margin: "0 auto" }}
            />
          </header>
        </AppHeader>

        <main>
          <SectionContainer>
            <CustomTextContainer
              className="empowering"
              style={{ flex: "none" }}
            >
              <Text.Text>
                Welcome To
                <br />{" "}
                <Text.Text className="bold-text pad-2">
                  {sessionParam["partner_portal_description"]}
                </Text.Text>
                <br /> fast, free Wi-Fi.
              </Text.Text>
            </CustomTextContainer>
            <div id="page1_display" width={300} height={600} />
          </SectionContainer>
        </main>

        <nav></nav>

        <footer className="footer-ed">
          <Box className="boxed">
            {/* <Btn onClick={refreshAds}>Click me to refresh</Btn> */}
            <ProgressBar
              page={1}
              className=" animated animatedFadeInUp fadeInUp"
            />
            <BtnContainer className=" animated animatedFadeInUp fadeInUp">
              <Btn onClick={() => setNextPage(true)}>CONTINUE</Btn>
            </BtnContainer>

            <LogoContainer className=" animated animatedFadeInUp fadeInUp">
              <CustomTextParagraph>Powered by</CustomTextParagraph>
            </LogoContainer>
            <div
              id="powerby"
              //style={{ width: '300px', height: '50px' , margin: '0 auto'}}
              width={300}
              height={50}
            />
            <Footer step={1} setModal={{ setModalState }} />

            {modalState ? <Terms setModal={{ setModalState }} /> : null}
          </Box>
        </footer>
      </section>
    </div>
  );
}

export default Ad1;
