import './App.css';
import AppConnect from './pages/app_connect'
import ReturnPage from './pages/Return_page';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<AppConnect />} />
            <Route exact path="/online" element={<ReturnPage/>} />
          </Routes>
      </BrowserRouter>

    </div> 
  );
}

export default App;
