import styled from 'styled-components';
import Colors from './Colors';

const Text = styled.span`
  color: ${Colors.LIGHT_BLUE};
  font-family: 'Open Sans', sans-serif;
  font-size: 3.1vh;
  font-style:normal;
  font-weight: 500;
`;

const Heading = styled(Text)`
  color: black;
  font-size: 3vh;
  font-weight: 400;
`;

const Paragraph = styled(Text)`
  color: black;
  font-size: 2vh;
  font-weight: 400;
`;
const Paragraph2 = styled(Text)`
  color: black;
  font-size: 1.9vh;
  font-weight: 400;
`;


export default { Text, Paragraph, Paragraph2, Heading };
