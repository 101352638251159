
/* global uiImages */
import React from 'react';
import '../App.css';

import styled from 'styled-components';

import Text from './Text';
import TOU from '../static/TOS.txt';

import CrossIcon from '../images/crossIcon';

const ModalContainer = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80vw;
  height: 128vh;
  // box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  padding: 20px;
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  overflow: auto;
  text-overflow: ellipsis; 
  height: 65vh;
  text-align: left;
`;

const ExitCross = styled.button`
  background: none;
  margin-left: 72vw;
  border: none;
`;

const HeaderContainer = styled.div`
  
`;

var textTOU = ""

fetch(TOU)
  .then(r => r.text())
  .then(text => {
    textTOU =  text.split(/\r\n|\r|\n|\t/).filter((el) => { return el.length > 0; })

  });

function Modal (props) {
    const { setModal } = props;

    const section1 = textTOU.slice(3,5)
    const section2 = textTOU.slice(6,8)
    const section3 = textTOU.slice(9,11)
    const section4 = textTOU.slice(12,13)
    const section5 = textTOU.slice(14,15)
    const section6 = textTOU.slice(16,29)
    const section7 = textTOU.slice(30,31)
    const section8 = textTOU.slice(32)

  return (
    <div>
    <ModalContainer>
        <ExitCross onClick={() => setModal.setModalState(false)}>
          { (typeof (uiImages) !== 'undefined' && uiImages.tosCloseBtnLogo !== undefined) ? <img alt="" src={uiImages.tosCloseBtnLogo} />
            : <CrossIcon width={20} />}
        </ExitCross>
        <HeaderContainer>
          <Text.Text>Terms of Service</Text.Text>
        </HeaderContainer>
        <br />
        <TextContainer>
        <Text.Heading> {textTOU[0]} </Text.Heading>
        {/* <Text.Heading className="modal-date"> { textTOU [1] } </Text.Heading> */}
          <Text.Paragraph>
            {section1.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.Paragraph>
          <Text.Heading> {textTOU[5]} </Text.Heading>
          <Text.Paragraph>
            {section2.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.Paragraph>
          <Text.Heading> {textTOU[8]} </Text.Heading>
          <Text.Paragraph>
            {section3.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.Paragraph>
          <Text.Heading> {textTOU[11]} </Text.Heading>
          <Text.Paragraph>
            {section4.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.Paragraph>
          <Text.Heading> {textTOU[13]} </Text.Heading>
          <Text.Paragraph>
            {section5.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })}  
          </Text.Paragraph>
          <Text.Heading> {textTOU[15]} </Text.Heading>
          <Text.Paragraph>
            {section6.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.Paragraph> 
          <Text.Heading> {textTOU[29]} </Text.Heading>
          <Text.Paragraph>
            {section7.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.Paragraph> 
          <Text.Heading> {textTOU[31]} </Text.Heading>
          <Text.Paragraph>
            {section8.map(function(para, index){
                                        return <p key={index}>{para}</p>;
                                    })} 
          </Text.Paragraph> 
        </TextContainer>
      </ModalContainer>
    </div>

  );
}

export default Modal;
