import styled from 'styled-components';

const TextContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  min-height: 8vh;
`;

const SvgContainer = styled.div`
  // height: 30vh;
`;

export {
  TextContainer,
  SvgContainer,
};
