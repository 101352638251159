import React, { useState, useEffect } from "react";
import "../App.css";

import { logs } from "../libs/apiCalls";
//import Woza from './OnlinePage';
import Authpage from "./Auth_page";
function RewardedVideo1({ sessionParam }) {
  const [RewardGranted, setRewardGranted] = useState(false);
  const [videoErrorState2, setVideoErrorState2] = useState(false);
  // const [showValid, setshowValid] = useState(false);
  const googletag = window.googletag || (window.googletag = { cmd: [] });

  function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/");
    return result[3];
  }

  function refreshAds() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    // var rewardedSlot;
    var rewardedSlot2;
    let RewardedValid2 = false;
    googletag.cmd.push(function () {
      var adPath = sessionParam["adPath"] + "video_sec";
      rewardedSlot2 = googletag.defineOutOfPageSlot(
        adPath,
        googletag.enums.OutOfPageFormat.REWARDED
      );

      // Slot returns null if the page or device does not support rewarded ads.

      if (rewardedSlot2) {
        rewardedSlot2.addService(googletag.pubads());

        // googletag.pubads().addEventListener('rewardedSlotReady', function (event) {
        //   var elementPath = event.slot.getAdUnitPath();
        //   var slotId = cleanAdUnitPath(elementPath);
        //     var Impressiondetails = {
        //       "sessionID": sessionParam['sessionID'],
        //       "event": "rewardedSlotReady",
        //       "slotID": slotId,
        //       "serviceName": "thinkzone",
        //       "slot": slotId
        //     }
        //     logs(Impressiondetails,sessionParam);

        //     event.makeRewardedVisible();
        // });

        googletag
          .pubads()
          .addEventListener("rewardedSlotClosed", function (event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
            //console.log(slotId)
            setTimeout(() => {
              if (RewardedValid2 === true) {
                dismissRewardedAd();
              } else {
                if (slotId === "video_sec") {
                  //console.log("session 2 video slot closed")
                  googletag.destroySlots([rewardedSlot2]);
                  setVideoErrorState2(true);
                }
              }
            }, 1000);
            // if (slotId === 'video_sec'){
            //   console.log("session 2 video slot closed")
            //   googletag.destroySlots([rewardedSlot2]);
            //   setVideoErrorState2(true)
            // }
          });

        googletag
          .pubads()
          .addEventListener("rewardedSlotGranted", function (event) {
            // var elementPath = event.slot.getAdUnitPath();
            // var slotId = cleanAdUnitPath(elementPath);
            // console.log('this is the slot id ' + slotId)
            // var rewardGrantedEvent2 = {
            //   "sessionID": sessionParam["sessionID"],
            //   "event": "rewardedSlotGranted",
            //   "slotID": slotId,
            //   "serviceName": "thinkzone",
            //   "slot": slotId,
            //   "payload": 1,
            // }
            // logs(rewardGrantedEvent2,sessionParam);
            RewardedValid2 = true;
            setTimeout(() => {
              dismissRewardedAd();
            }, 2000);
          });

        googletag.enableServices();
        // googletag.display(rewardedSlot);
        googletag.display(rewardedSlot2);
        //refreshAds();
      }
    });

    function dismissRewardedAd() {
      // googletag.destroySlots([rewardedSlot]);
      googletag.destroySlots([rewardedSlot2]);
      setRewardGranted(true);
    }
  }, [sessionParam]);
  if (videoErrorState2) {
    //console.log('I am going to return authpage')
    var sessionData = sessionParam;
    sessionData["session"] = "session1";
    return <Authpage sessionParam={sessionData} />;
  }

  if (RewardGranted) {
    var sessionData1 = sessionParam;
    sessionData1["session"] = "session2";

    return (
      //<div id="modals">
      <Authpage sessionParam={sessionData1} />
      //</div>
    );
  }
}

export default RewardedVideo1;
