import React from 'react';
import '../App.css';

import { Bars } from 'react-loader-spinner';
// import { Watch } from 'react-loader-spinner';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import styled from 'styled-components';
// import AdHeader from '../components/AdHeader';
import Colours from '../components/Colors';
import Text from '../components/Text';

import {
//   AppContainer,
//   HeaderContainer,
  SectionContainer,
} from '../components/ResponsiveLayout';

const loaderSize = 250;

const StyledDiv = styled.div`
  width: ${loaderSize}px;
  margin-top: 40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: ${Colours.DEMO_DARK_GRAY};
`;

function SpinnerPage() {
  return (
    <div className='spinner'>
         <section id="page">
            <header>

            </header>

            <main>
            <SectionContainer page={1}>
                <StyledDiv>
                    <Bars color={Colours.VIOLET_PURP} height={loaderSize} width={loaderSize} />
                        <Text.Text>Please wait ...</Text.Text>
                </StyledDiv>
            </SectionContainer>
            </main>

            <nav>

            </nav>
            <footer>

            </footer>
            </section>
    </div>
  );
}

export default SpinnerPage;
