import * as apiCall from './ajaxConnector'
//import { chilliController } from './chilli/ChilliLibrary';

const BASE_API_URL = 'https://apigw.thinkwifizone.com'
const token = 'akljnv13bvi2vfo0b0bw'

//Authentication for Ruckus and Coovachilli
export const auth = async (sessionData, userDomain) => {
    var username = (`${sessionData.sessionID}@${userDomain}`)
    if (sessionData.vendor === 'ruckus') {
        sessionData['username'] = username;
        let auth_result = await (apiCall.post(`${BASE_API_URL}/api/v1/auth/thinkzone/${sessionData.vendor}/session`, sessionData, { headers: {"Authorization" : `Bearer ${token}`} }))
        if ('error' in auth_result.data){
            return {'status': false}
        } else {
            return {'status': true}
        }
        
        
    } 

}