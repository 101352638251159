import React, { useState, useEffect } from "react";
import { logs } from "../libs/apiCalls";
import RouteOnline1 from "./RouteOnline1";
import RouteOnline2 from "./RouteOnline2";
import Spinner from "./Spinner";
import "../App.css";
const googletag = window.googletag || (window.googletag = { cmd: [] });
const urlParams1 = new URLSearchParams(window.location.search);

function getURLParams() {
  const params = {};
  for (const [key, value] of urlParams1.entries()) {
    params[key] = value;
  }
  return params;
}

function cleanAdUnitPath(adUnitPath) {
  var result = adUnitPath.split("/");
  return result[3];
}

async function initializeAds(Targeting, sessionID, sessionData) {
  googletag.cmd.push(function () {
    // This listener will be called when an impression is considered viewable.
    googletag.pubads().addEventListener("impressionViewable", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);
      var Impressiondetails = {
        sessionID: sessionID,
        event: "impressionViewable",
        slotID: slotId,
        serviceName: "thinkzone",
        slot: slotId,
      };
      logs(Impressiondetails, sessionData);
    });

    googletag.pubads().addEventListener("slotOnload", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);

      var onloadEvents = {
        sessionID: sessionID,
        event: "slotOnload",
        slotID: slotId,
        serviceName: "thinkzone",
        slot: slotId,
      };
      logs(onloadEvents, sessionData);
    });

    // This listener will be called when a slot has finished rendering.
    googletag.pubads().addEventListener("slotRenderEnded", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);
      if (event.isEmpty) {
        console.log("No ad was returned for slot " + slotId);
      }

      var details = {
        sessionID: sessionID,
        event: "slotRenderEnded",
        slotID: slotId,
        slot: slotId,
        serviceName: "thinkzone",
        advertiserId: event.advertiserId,
        campaignId: event.campaignId,
        companyId: event.companyIds,
        creativeId: event.creativeId,
        creativeTemplateId: event.creativeId,
        "isBackfill?": event.isBackfill,
        "isEmpty?": event.isEmpty,
        labelIDs: event.labelIds,
        lineItemId: event.lineItemId,
        size: event.size,
        "slotContentChanged?": event.slotContentChanged,
        sourceAgnosticCreativeId: event.sourceAgnosticCreativeId,
        sourceAgnosticLineItemId: event.sourceAgnosticLineItemId,
        yieldGroupId: event.yieldGroupIds,
      };

      logs(details, sessionData);
    });

    googletag.pubads().addEventListener("slotRequested", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);

      var requestEvents = {
        sessionID: sessionID,
        event: "slotRequested",
        slotID: slotId,
        serviceName: "thinkzone",
        slot: slotId,
      };

      logs(requestEvents, sessionData);
    });

    // This listener will be called when an ad response has been received for
    // a slot.
    googletag
      .pubads()
      .addEventListener("slotResponseReceived", function (event) {
        var elementPath = event.slot.getAdUnitPath();
        var slotId = cleanAdUnitPath(elementPath);

        var receivedEvents = {
          sessionID: sessionID,
          event: "slotResponseReceived",
          slotID: slotId,
          serviceName: "thinkzone",
          slot: slotId,
        };
        logs(receivedEvents, sessionData);
      });
  });

  googletag.cmd.push(function () {
    googletag.pubads().clearTargeting();
    delete Targeting["ad_network_num"];
    delete Targeting["ad_network_label"];
    Object.keys(Targeting).map((key) =>
      googletag.pubads().setTargeting(key, Targeting[key])
    );
  });

  googletag.cmd.push(function () {
    googletag.pubads().enableSingleRequest();
    googletag.pubads().disableInitialLoad();
  });

  googletag.cmd.push(function () {
    googletag.enableServices();
  });
  return true;
}

function ReturnPage() {
  const [loading, setLoading] = useState(true);
  const [enablingAds, setEnablingAds] = useState(true);
  const [isAdsEnabled, setIsAdsEnabled] = useState(0);
  const [finalParams, setFinalParams] = useState(null);

  useEffect(() => {
    if (!enablingAds) {
      if (googletag.pubadsReady) {
        //console.log("pubadsReady");
        setLoading(false);
      } else {
        let count_enabled = isAdsEnabled + 1;
        setTimeout(() => {
          setIsAdsEnabled(count_enabled);
        }, 200);
      }
    }
  }, [enablingAds, isAdsEnabled]);

  useEffect(() => {
    //var urlParams = getURLParams();
    if (urlParams1) {
      var urlParams = getURLParams();
      var Targeting = {};
      Targeting["lsale"] = urlParams.target_lsale;
      Targeting["partner"] = urlParams.partner;
      let sessionData = urlParams;
      let sessionID = urlParams.sessionID;
      initializeAds(Targeting, sessionID, sessionData).then((result) => {
        if (result) {
          setEnablingAds(false);
          setFinalParams(urlParams);
        }
      });
    }
  }, []);

  if (loading) return <Spinner />;
  if (finalParams.session === "session2") {
    return <RouteOnline2 sessionParam={finalParams} />;
  } else {
    return <RouteOnline1 sessionParam={finalParams} />;
  }
}

export default ReturnPage;
