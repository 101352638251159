import "../App.css";
import { useState, useEffect } from "react";
import styled from "styled-components";

import Rewarded2 from "./Page5Rewarded2";
// import Footer from './Footer';

import Btn from "../components/Button";
// import Button from '@mui/material/Button';
import Text from "../components/Text";

// import Terms from '../components/TermsModal';

import ProgressBar from "../components/ProgressBar";

import { createSlots, gptDestroySlots } from "../libs/gptAdsManager";

// import {
//     AdvertisingProvider,
//     AdvertisingSlot,
//   } from 'react-advertising';
import {
  // AppContainer,
  // HeaderContainer,
  SectionContainer,
  BtnContainer,
} from "../components/ResponsiveLayout";

const TextContainer = styled.div`
  display: flex;
  text-align: center;
  margin: 1vh;
  justify-content: center;
  flex-direction: column;
  min-height: 11vh;
`;

const AppHeader = styled.div`
  padding: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;
// const TextDescription = styled(Text.Text)`
//   font-size: 1.8vh;
// `;

const Box = styled.div`
  background: #fff;
  margin: auto;
  //width: 30vw;
  max-width: 50vh;
  height: 14vh;
  box-shadow: 0 9px 17px 12px rgba(195, 192, 192, 0.5);
  padding: 3px 20px 20px 20px;
  white-space: pre-wrap;
`;

const googletag = window.googletag || (window.googletag = { cmd: [] });

function Page4({ sessionParam }) {
  const [NextPage, setNextPage] = useState(false);
  const [loadTimeoutHandler, setLoadTimeoutHandler] = useState(null);
  const [isLoading, setLoading] = useState(true);

  function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/");
    return result[3];
  }
  function refreshAds() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  useEffect(() => {
    googletag.pubads().addEventListener("slotOnload", function (event) {
      var elementPath = event.slot.getAdUnitPath();
      var slotId = cleanAdUnitPath(elementPath);
      if (slotId === "page4_display") {
        setTimeout(cenas, 2000);
      }
    });
  }, []);

  useEffect(() => {
    var adSlots = {
      slots: [
        {
          id: "page4_header",
          sizes: [
            [320, 50],
            [300, 100],
            [300, 50],
          ],
        },
        {
          id: "page4_display",
          sizes: [
            [300, 600],
            [120, 600],
            [160, 600],
            [300, 250],
            [336, 280],
          ],
        },
      ],
    };
    var adPath = sessionParam["adPath"];

    createSlots(adPath, adSlots);
    //refreshAds();
  }, [sessionParam]);

  useEffect(() => {
    setLoadTimeoutHandler(setTimeout(() => setLoading(false), 1300));
    return () => {
      clearTimeout(loadTimeoutHandler);
    };
  }, []);

  if (NextPage) {
    gptDestroySlots();
    return <Rewarded2 sessionParam={sessionParam} />;
  }

  // This is a Javascript Function that handles a timer and attaches css depending on the setTimeout
  setTimeout(cenas, 3000);
  function cenas() {
    var elementos = document.querySelectorAll(".faded-in");
    elementos.forEach(muda);
  }

  function muda(item, index) {
    item.style.opacity = 1;
    item.style.transition = "opacity 1s";
  }

  return (
    <div className="page-2">
      <section id="page">
        <AppHeader>
          <header>
            {/* <AdvertisingSlot id="page1_header" /> */}
            <div
              id="page4_header"
              style={{ width: "320px", height: "50px", margin: "0 auto" }}
            />
          </header>
        </AppHeader>

        <main>
          <SectionContainer>
            <TextContainer style={{ flex: "none" }}>
              <Text.Text className="bold-text">Almost there...</Text.Text>
              {/* <TextDescription>
                                {` Watch an video to enjoy 60 min of fast, free Wi-Fi. `}
                            </TextDescription> */}
            </TextContainer>
            <div
              id="page4_display"
              style={{ width: "336px", height: "280px", margin: "0 auto" }}
            />
            {/* <AdvertisingSlot id="page2_display_test2" /> */}
          </SectionContainer>
        </main>

        <nav></nav>
        <footer className="footer-ed">
          <Box className="faded-in">
            <ProgressBar page={2} />
            <BtnContainer>
              <Btn disabled={isLoading} onClick={() => setNextPage(true)}>
                CONNECT
              </Btn>
            </BtnContainer>
            <Text.Paragraph2>
              Watch a video to enjoy 60 minutes of fast, free Wi-Fi.
            </Text.Paragraph2>
          </Box>
        </footer>
      </section>
    </div>
  );
}

export default Page4;
