import'../App.css';
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import ReactGA from "react-ga4";
//import libs
import { prechecks, getAd, logs } from '../libs/apiCalls';
//import pages
import SpinnerPage from './Spinner';
import ErrorPage from './ErrorPage';
import Page1 from './page1'
//import { request } from 'http';

const sessionID = uuidv4();
const urlParams = new URLSearchParams(window.location.search)
const url = "https://connected.thinkwifizone.com"
const googletag = window.googletag || (window.googletag = { cmd: [] });


function getURLParams(){
    const params = {};
    for (const [key,value] of urlParams.entries()){
        params[key] = value;
    }
    return params;
};

function cleanAdUnitPath(adUnitPath) {
    var result = adUnitPath.split("/")
    return result[3]
  };


async function initializeAds(Targeting, sessionID, sessionData){
    googletag.cmd.push(function() {
   
        // This listener will be called when an impression is considered viewable.
        googletag.pubads().addEventListener('impressionViewable', function(event) {
          var elementPath = event.slot.getAdUnitPath();
          var slotId = cleanAdUnitPath(elementPath);
          var Impressiondetails = {
           'sessionID': sessionID,
           'event': "impressionViewable",
           'slotID': slotId,
           'serviceName': "thinkzone",
           'slot': slotId,
          }
         logs(Impressiondetails, sessionData)
   
        });
   
        googletag.pubads().addEventListener('slotOnload', function(event) {
          var elementPath = event.slot.getAdUnitPath();
          var slotId = cleanAdUnitPath(elementPath);
          
          var onloadEvents = {
           'sessionID': sessionID,
           'event': "slotOnload",
           'slotID': slotId,
           'serviceName': "thinkzone",
           'slot': slotId,
          }
          logs(onloadEvents,sessionData)
        });
   
        // This listener will be called when a slot has finished rendering.
        googletag.pubads().addEventListener('slotRenderEnded', function(event) {
          var elementPath = event.slot.getAdUnitPath();
          var slotId = cleanAdUnitPath(elementPath);
          //var creativeIdResult = event.creativeId && event.creative.creativeUrl;
          // if (event.isEmpty) {
          //   console.log('No ad was returned for slot ' + slotId);
          // }
    
          var details = {
            'sessionID': sessionID,
            'event': "slotRenderEnded",
            'slotID': slotId,
            'slot': slotId,
            'serviceName': "thinkzone",
            'advertiserId': event.advertiserId,
            'campaignId': event.campaignId,
            'companyId': event.companyIds,
            'isBackfill?': event.isBackfill,
            'isEmpty?': event.isEmpty,
            'labelIDs': event.labelIds,
            'lineItemId': event.lineItemId,
            'size': event.size,
            'slotContentChanged?': event.slotContentChanged,
            'sourceAgnosticCreativeId': event.sourceAgnosticCreativeId,
            'sourceAgnosticLineItemId': event.sourceAgnosticLineItemId,
            'yieldGroupId': event.yieldGroupIds
          }
   
        logs(details,sessionData)
        });

        googletag.pubads().addEventListener('slotRequested', function(event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
     
            var requestEvents = {
             'sessionID': sessionID,
             'event': "slotRequested",
             'slotID': slotId,
             'serviceName': "thinkzone",
             'slot': slotId,
            }
     
            logs(requestEvents,sessionData)
          });
     
     
          // This listener will be called when an ad response has been received for
          // a slot.
          googletag.pubads().addEventListener('slotResponseReceived', function(event) {
            var elementPath = event.slot.getAdUnitPath();
            var slotId = cleanAdUnitPath(elementPath);
     
            var receivedEvents = {
             'sessionID': sessionID,
             'event': "slotResponseReceived",
             'slotID': slotId,
             'serviceName': "thinkzone",
             'slot': slotId,
            }
            logs(receivedEvents,sessionData) 
          });
        });

        googletag.cmd.push(function() {
            googletag.pubads().clearTargeting();
            delete Targeting["ad_network_num"]
            delete Targeting["ad_network_label"]
            Object.keys(Targeting).map(key => (
                googletag.pubads().setTargeting(key, Targeting[key])
            ));
        });

      //   googletag.cmd.push(function() {
      //     googletag.pubads().disableInitialLoad();
      // });

        googletag.cmd.push(function() {
        googletag.enableServices();
    });
    return true
        };
        

async function getPrechecks(data) {
    try {
      const precheckResult = (await prechecks(data)).data

      if ('status' in precheckResult){
        return {status: "error"}
      } else if ('detail' in precheckResult){
        return {status: "error"}
      }else {
        return precheckResult
      }
    } catch(e) {
      return {status: "error"}
    } finally {

    }
  };

  async function getAdTargeting(targetingData) {
    try {
      var TargetingResult = {}
      if ('lsale' in targetingData){
        if (!('partner' in targetingData)){
          TargetingResult.partner = "TW"
          TargetingResult.lsale = targetingData.lsale
          TargetingResult.partner_name = "ThinkZone"
        } else {
          TargetingResult.partner = targetingData.partner
          TargetingResult.lsale = targetingData.lsale
          TargetingResult.partner_name = targetingData.partner_portal_description
        }
      } else {
        TargetingResult = (await getAd(targetingData)).data;
        TargetingResult['partner'] = targetingData.partner;
        TargetingResult['apMac'] = targetingData.ap_mac_norm;
      }
      if ('status' in TargetingResult){
        return {lsale: "Default"}
      } else {
        return TargetingResult
      };
    } catch(e) {
        return {status: "error"}
    } finally {

    }
  };





function App_Connect(){
    const [loading, setLoading] = useState(true);
    const [error, setErrorState] = useState();
    const [DoPreChecks, setDoPreChecks] = useState(false);
    const [sessionData, setSessionData] = useState();
    const [PrechecksDone, setPrechecksDone] = useState(false);
    const [Targeting, setTargeting] = useState(false);
    const [sessionDataFinal, setSessionDataFinal] = useState();
    const [enablingAds, setEnablingAds] = useState(true);
    const [isAdsEnabled, setIsAdsEnabled] = useState(0);
    

    useEffect(() => {
        if (!enablingAds){
            if (googletag.pubadsReady) {
                setLoading(false)} 
                else {
                    let count_enabled = isAdsEnabled + 1
                    setTimeout(() => {setIsAdsEnabled(count_enabled)}, 200)
                }
        }
        
    },[enablingAds, isAdsEnabled]);

    useEffect(() => {
        if (Targeting && enablingAds){
            let sessionDataUpdate = {...sessionData};
            let constructURL = {}
            sessionDataUpdate["target_lsale"] = Targeting.lsale
            constructURL["partner"] = sessionData.partner
            constructURL["target_lsale"] = Targeting.lsale
            constructURL["sessionID"] = sessionID
            constructURL["client_mac_norm"] = sessionData.client_mac_norm
            constructURL["ap_mac_norm"] = sessionData.ap_mac_norm
            constructURL["partner_name"] = sessionData.partner_portal_description
            
            let queryAdPath = ""
            if (!Targeting.ad_network_num){
                queryAdPath = "/21967555500/thinkzone/"
            }else{
                queryAdPath = `/${Targeting.ad_network_num}/${Targeting.ad_network_label}/`
            }
            sessionDataUpdate["adPath"] = queryAdPath
            constructURL["adPath"] = queryAdPath

            let urlURI = new URLSearchParams(constructURL).toString();
            let redirectURL = url + '/online/?' + urlURI
            sessionDataUpdate["openwifi_redirect_url"] = redirectURL
            sessionDataUpdate["sessionID"] = sessionID
            initializeAds(Targeting, sessionID, sessionData).then((result) => {
                if (result){
                    setSessionDataFinal(sessionDataUpdate)
                    setEnablingAds(false)
                }
            });

        }
    }, [Targeting, sessionData,enablingAds]);

    useEffect(() => {
        if (PrechecksDone){
            getAdTargeting(sessionData).then((result) => {
                if (result.status === "error"){
                    setErrorState("Failed to load static add'")
                } else {
                    setTargeting(result)
                }
            });
        }
    }, [PrechecksDone, sessionData]);
    
    useEffect(() => {
        if (DoPreChecks) {
            if ('lsale' in sessionData || 'partner' in sessionData){
                setPrechecksDone(true);
            } else {
            getPrechecks(sessionData).then((result) => {
                if (result.status === "error"){
                    setErrorState("Prechecks Failed")
                } else {
                    if (result.google_analytics_tag){
                        ReactGA.initialize([
                          {
                            trackingId: "G-19J7ZKDQ5V",
                          },
                          {
                            trackingId: result.google_analytics_tag,
                          },
                        ]);
                      }else{
                        ReactGA.initialize([
                          {
                            trackingId: "G-19J7ZKDQ5V",
                          },
                        ]);
                      }
                    var sessionDataUpdate = {...sessionData, ...result};
                    sessionDataUpdate["sessionID"] = sessionID
                    setSessionData(sessionDataUpdate);
                    setPrechecksDone(true);
                    setDoPreChecks(false);
                }
            });
        }
    }

    }, [DoPreChecks,sessionData]);

    useEffect(() => {
        let urlParamsObject = getURLParams();
        const isEmpty = Object.keys(urlParamsObject).length === 0;
        if (isEmpty) {
            setErrorState("Prechecks Failed")
        } else {
            setSessionData(urlParamsObject);
            setDoPreChecks(true);
        }

    }, []);

    if(error){
        return <ErrorPage errMsg={error}/>;
    }
    if(loading){
        return <SpinnerPage />;
    }
    return <Page1 sessionParam = {sessionDataFinal} />;
   
    
}
export default App_Connect;
