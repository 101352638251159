import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Color from './Colors';
import Dot from '../images/dotImg';

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1vh;
`;

const ProgressBar = (props) => {
  const { page } = props;

  return (
    <>
      <ProgressContainer>
        <Dot width={14} height={8} color={page === 1 ? Color.VIOLET_PURP : Color.DEMO_GREY} />
        <Dot width={14} height={8} color={page === 2 ? Color.VIOLET_PURP : Color.DEMO_GREY} />
        <Dot width={14} height={8} color={page === 3 ? Color.VIOLET_PURP : Color.DEMO_GREY} />
        <Dot width={14} height={8} color={page === 4 ? Color.VIOLET_PURP : Color.DEMO_GREY} />
      </ProgressContainer>
    </>
  );
};

ProgressBar.propTypes = {
  page: PropTypes.number.isRequired,
};

export default ProgressBar;
