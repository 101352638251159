import * as apiCall from './ajaxConnector'

const LOGS_BASE_API_URL = 'https://logs.thinkwifizone.com'
const BASE_API_URL = 'https://apigw.thinkwifizone.com'
const token = 'akljnv13bvi2vfo0b0bw'



export const logs = async (logs,sessionData) => {
    const result = await apiCall.post(`${LOGS_BASE_API_URL}/logging/${logs.event}/${sessionData.client_mac_norm}/${sessionData.ap_mac_norm}`, logs)
    return result
    //console.log(logs)
    //console.log(sessionData)
    //return {status: "testing"}
}

export const prechecks = async (urlParms) => {
        const result = await apiCall.post(`${BASE_API_URL}/api/v1/prechecks`, urlParms, { headers: {"Authorization" : `Bearer ${token}`} })
        return result 
}

export const getAd = async (urlParms) => {
    const result = await apiCall.get(`${BASE_API_URL}/api/v1/getAd/${urlParms.ap_mac_norm}/${urlParms.client_mac_norm}`, { headers: {"Authorization" : `Bearer ${token}`} })
    return result 
}

export async function prechecks1(urlParms){
    const result = await apiCall.post(`${BASE_API_URL}/api/v1/prechecks`, urlParms, { headers: {"Authorization" : `Bearer ${token}`} })
    console.log(result.data)
    return result 
}





