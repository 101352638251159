import { useState, useEffect } from 'react';

import Rewarded2 from '../pages/Page4Connect';
import Authpage from '../pages/Auth_page'

import styled from 'styled-components';
import Text from './Text';
import Btn from '../components/Button';
import ProgressBar from '../components/ProgressBar';

import {createSlots, gptDestroySlots} from '../libs/gptAdsManager'

const ModalContainer = styled.div`
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80vw;
  height: 68vh;
  // box-shadow: 0 5px 10px 2px rgba(195,192,192,.5);
  padding: 20px;
  white-space: pre-wrap;
`;

const TextContainer = styled.div`
  overflow: auto;
  text-overflow: ellipsis; 
//   height: 56vh;
`;

const AppTest = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;

const googletag = window.googletag || (window.googletag = { cmd: [] });
const Modal = ({sessionParam}) => {

const [NextPage, setNextPage] = useState(false);
const [NextPage2, setNextPage2] = useState(false);
const [loadTimeoutHandler, setLoadTimeoutHandler] = useState(null);
const [isLoading, setLoading] = useState(true);

function refreshAds() {
  googletag.cmd.push(() => {
    googletag.pubads().refresh();
  });
}

useEffect(() => {
  var adSlots = {
    slots: [
      {
        id: "modal_header",
        sizes: [[320, 50],[300, 100],[300, 50]],
      },
      {
        id: "video_modal",
        sizes: [[336, 280],[300, 250],[200, 200],[250, 250]]
      },
    ]
  }
  var adPath = sessionParam['adPath'];
  createSlots(adPath, adSlots);
  refreshAds();
},[sessionParam]);

useEffect(() => {
  setLoadTimeoutHandler(setTimeout(() => setLoading(false), 1300));
  return () => { clearTimeout(loadTimeoutHandler); };
}, []);

if (NextPage) {
  gptDestroySlots();
    return <Rewarded2 sessionParam = {sessionParam}/>;
}
if (NextPage2) {
  gptDestroySlots();
  var sessionData = sessionParam
  sessionData['session'] = 'session1';
  return <Authpage sessionParam = {sessionData}/>;
}

  return (
    <div className='modal'>
      <AppTest>
      <ModalContainer className='modal-con'>
            <div 
              id="modal_header"
              width={300}
              height={100}
              //style={{ width: '300px', height: '100px' , margin: '0 auto'}}
            />
            <div className='extend-text'>
              <Text.Text className="bold-text">Extend</Text.Text>
              <Text.Text> your session to 60minutes?</Text.Text>
            </div>           
        <br />
        <TextContainer>
          <Text.Paragraph>
            <div className='container'>
                <div className='row-center'>
                    <div className='col-6-l'>
                        <Btn disabled={isLoading} className='main-button' variant="contained"  onClick={() => setNextPage(true)}>Yes</Btn>
                    </div>
                    <div className='col-6-r'>
                        <Btn disabled={isLoading} className='main-button' variant="contained"  onClick={() => setNextPage2(true)}>No</Btn>   
                    </div>
                </div>
            </div>
     
          </Text.Paragraph>
        </TextContainer>
        <ProgressBar page={3} /> 
        
          <div 
            id="video_modal"
            width={336}
            height={280}
          />
      </ModalContainer>
      </AppTest>
    </div>
  );
};

export default Modal;
