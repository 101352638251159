

const googletag = window.googletag || (window.googletag = { cmd: [] });

export const createSlots = (adPath, adSlots ) => {
          
  if (!adPath) {
    adPath = '/21967555500/thinkzone/'
  }
  var slots = adSlots["slots"]
  var i ;
  googletag.cmd.push(function () {
    for (i=0; i < slots.length; i++){
      let netPath = adPath + slots[i]["id"]
      var sizes = slots[i]["sizes"]
      googletag.defineSlot(netPath, sizes, slots[i]["id"]).addService(googletag.pubads());

  }


  });
  googletag.cmd.push(function() {
    for (i=0; i < slots.length; i++){
    googletag.display(slots[i]["id"]);
    }
  });

};

export const gptDestroySlots = () => {
  googletag.destroySlots();
};