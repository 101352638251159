import React from 'react';
import { useState, useEffect } from 'react';
import { auth } from '../libs/authentication'
import Spinner from './Spinner';
import ErrorPage from './ErrorPage';
import Online from './OnlinePage'
import Online2 from './OnlinePage2';

const Authpage = ({sessionParam}) => {
  const [loading, setLoading] = useState(true);
  const [error, setErrorState] = useState(false);


  async function authenticate(authData, Domain) {
    try {
      if ('lsale' in authData) {
        setLoading(false)
      }
      else if (authData.vendor === 'openwifi'){
        var password = 'Rl8zLPZ4w7L7'
        var username = (`${authData.sessionID}@${Domain}`)
        var redirectURL = authData.openwifi_redirect_url
        var session = authData.session
        var redirectURLSession = redirectURL + '&session=' + session 
        var AuthUrl = `http://${authData.controller_ip}:${authData.controller_port}/logon?username=${username}&password=${password}&userurl=${redirectURLSession}`
        window.location.replace(AuthUrl)
      } 
      else {
        const auth_result = await (auth(authData, Domain))
        if (auth_result.status) {
          setLoading(false)
        } else {
          setErrorState(true)
        }
      }
      
    } catch(e) {
      setErrorState("Authentication Failed")
    } finally {

    }
  };


  useEffect(()=>{
    if (sessionParam.session === 'session1'){
        authenticate(sessionParam, 'thinkzone')
    } else if (sessionParam.session === 'session2'){
        authenticate(sessionParam, 'thinkzone2')
    }
  },[sessionParam])

    if (error) return <ErrorPage errMsg={error}/>;
    if (loading) return <Spinner />;
    if (!loading) {
      if (sessionParam.session === 'session1'){
        return <Online sessionParam={sessionParam} />;
      } else if (sessionParam.session === 'session2') {
        return <Online2 sessionParam={sessionParam} />;
      }
    }

}

export default Authpage;