// 30Min Reroute Page
import React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";
import Text from "../components/Text";

import ProgressBar from "../components/ProgressBar";

import { TextContainer } from "../components/Layout";

import { createSlots } from "../libs/gptAdsManager";

const CustomTextContainer = styled(TextContainer)`
  margin: 5vh 8vh 1vh;
  display: block;
`;

const HeaderText = styled(Text.Text)`
  margin-bottom: 1vh;
  display: block;
`;

const HeaderInfo = styled(Text.Text)`
  font-size: 2vh;
  display: block;
  padding: 5px;
`;
const AppHeader = styled.div`
  padding: 2vh;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
`;

const RouteOnline1 = ({ sessionParam }) => {
  const [seconds, setSeconds] = useState(1800);

  const googletag = window.googletag || (window.googletag = { cmd: [] });

  function refreshAds() {
    googletag.cmd.push(() => {
      googletag.pubads().refresh();
    });
  }

  useEffect(() => {
    var adSlots = {
      slots: [
        {
          id: "online_header",
          sizes: [[320, 50]],
        },
        {
          id: "online_display",
          sizes: [[300, 250]],
        },
        {
          id: "browseb",
          sizes: [[211, 46]],
        },
      ],
    };
    var adPath = sessionParam["adPath"];
    createSlots(adPath, adSlots);
    //refreshAds();
  }, [sessionParam]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) setSeconds((v) => v - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="online-page">
      <section id="page">
        <AppHeader>
          <header>
            <div
              id="online_header"
              style={{ width: "300px", height: "50px", margin: "0 auto" }}
            />
          </header>
        </AppHeader>
        <main>
          <CustomTextContainer>
            <HeaderText>Congratulations</HeaderText>
            <HeaderInfo className="bold-text">
              You&#39;re now connected to {sessionParam["partner_name"]} Free
              Wi-Fi
            </HeaderInfo>
            <Text.Text className="num-pad">
              {Math.floor(seconds / 60)}:
              {"".concat(seconds % 60).padStart(2, "0")}
            </Text.Text>
            <HeaderInfo>Minutes left</HeaderInfo>
            <HeaderInfo className="bold-text">Happy browsing!!!!</HeaderInfo>
          </CustomTextContainer>

          <div className="main-block">
            <div
              id="online_display"
              style={{ width: "300px", height: "250px", margin: "0 auto" }}
            />
          </div>
        </main>

        <nav>
          <ProgressBar page={4} />
          <div
            id="browseb"
            style={{ width: "221px", height: "46px", margin: "0 auto" }}
          />
        </nav>

        <footer></footer>
      </section>
    </div>
  );
};

export default RouteOnline1;
