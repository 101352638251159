import React from 'react';
import styled from 'styled-components';

// import ThinkWifiCompanyLogo from '../images/thinkWifiCompanyLogo';

import Text from '../components/Text';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  //min-height: 10vh;
  box-sizing: border-box;
  padding-top: 20px;
`;

const InfoTextParagraph = styled(Text.Paragraph)`
  font-size: 1.4vh;
//   visibility: ${(props) => (props.step !== 1 ? "hidden" : "visible")};
`;

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 1.4vh;
  margin-left: 1vh;
`;


const Footer = (props) => {
  // const [showModal, setShowModal] = useState(false);
  const { step, setModal } = props;

  return (
    <>
      <FooterContainer>
            {/* <ThinkWifiCompanyLogo /> */}
            {/* {typeof uiImages !== 'undefined' &&
            uiImages.footerLogo !== undefined ? (
              <img src={uiImages.footerLogo} alt="footerLogo" />
              ) : (
                <ThinkWifiCompanyLogo />
              )} */}
              {/* <img src={Logo} alt="thinkLogo" /> */}

        <InfoTextParagraph >
          By connecting, you agree to the Think Wifi
          <LinkButton onClick={() => setModal.setModalState(true)}>
            Terms of Service
          </LinkButton>
        </InfoTextParagraph>
      </FooterContainer>
                 {/* <p onClick={() => setShowModal(true)}>Terms of Service</p> */}
          {/* <Terms show={showModal} close={() => setShowModal(false)} /> */}
    </>
  );
};

export default Footer;
